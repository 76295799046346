import { ChainNotConfiguredError, ProviderNotFoundError, createConnector } from '@wagmi/core';
import '@walletconnect/universal-provider';
import { SwitchChainError, UserRejectedRequestError, getAddress, numberToHex } from 'viem';
import { WcHelpersUtil } from '@reown/appkit';
import { StorageUtil } from '@reown/appkit-core';
import { ConstantsUtil } from '@reown/appkit-common';
walletConnect.type = 'walletConnect';
export function walletConnect(parameters, appKit, caipNetworks) {
  const isNewChainsStale = parameters.isNewChainsStale ?? true;
  let provider_;
  let accountsChanged;
  let chainChanged;
  let connect;
  let displayUri;
  let sessionDelete;
  let disconnect;
  return createConnector(config => ({
    id: 'walletConnect',
    name: 'WalletConnect',
    type: walletConnect.type,
    async setup() {
      const provider = await this.getProvider().catch(() => null);
      if (!provider) {
        return;
      }
      if (!connect) {
        connect = this.onConnect.bind(this);
        provider.on('connect', connect);
      }
      if (!sessionDelete) {
        sessionDelete = this.onSessionDelete.bind(this);
        provider.on('session_delete', sessionDelete);
      }
    },
    async connect({
      ...rest
    } = {}) {
      try {
        const provider = await this.getProvider();
        if (!provider) {
          throw new ProviderNotFoundError();
        }
        if (!displayUri) {
          displayUri = this.onDisplayUri;
          provider.on('display_uri', displayUri);
        }
        const isChainsStale = await this.isChainsStale();
        if (provider.session && isChainsStale) {
          await provider.disconnect();
        }
        if (!provider.session || isChainsStale) {
          const namespaces = WcHelpersUtil.createNamespaces(caipNetworks);
          await provider.connect({
            optionalNamespaces: namespaces,
            ...('pairingTopic' in rest ? {
              pairingTopic: rest.pairingTopic
            } : {})
          });
          this.setRequestedChainsIds(caipNetworks.map(x => Number(x.id)));
        }
        const accounts = (await provider.enable()).map(x => getAddress(x));
        const currentChainId = await this.getChainId();
        if (displayUri) {
          provider.removeListener('display_uri', displayUri);
          displayUri = undefined;
        }
        if (connect) {
          provider.removeListener('connect', connect);
          connect = undefined;
        }
        if (!accountsChanged) {
          accountsChanged = this.onAccountsChanged.bind(this);
          provider.on('accountsChanged', accountsChanged);
        }
        if (!chainChanged) {
          chainChanged = this.onChainChanged.bind(this);
          provider.on('chainChanged', chainChanged);
        }
        if (!disconnect) {
          disconnect = this.onDisconnect.bind(this);
          provider.on('disconnect', disconnect);
        }
        if (!sessionDelete) {
          sessionDelete = this.onSessionDelete.bind(this);
          provider.on('session_delete', sessionDelete);
        }
        return {
          accounts,
          chainId: currentChainId
        };
      } catch (error) {
        if (/(user rejected|connection request reset)/i.test(error?.message)) {
          throw new UserRejectedRequestError(error);
        }
        throw error;
      }
    },
    async disconnect() {
      const provider = await this.getProvider();
      try {
        await provider?.disconnect();
      } catch (error) {
        if (!/No matching key/i.test(error.message)) {
          throw error;
        }
      } finally {
        if (chainChanged) {
          provider?.removeListener('chainChanged', chainChanged);
          chainChanged = undefined;
        }
        if (disconnect) {
          provider?.removeListener('disconnect', disconnect);
          disconnect = undefined;
        }
        if (!connect) {
          connect = this.onConnect.bind(this);
          provider?.on('connect', connect);
        }
        if (accountsChanged) {
          provider?.removeListener('accountsChanged', accountsChanged);
          accountsChanged = undefined;
        }
        if (sessionDelete) {
          provider?.removeListener('session_delete', sessionDelete);
          sessionDelete = undefined;
        }
        this.setRequestedChainsIds([]);
      }
    },
    async getAccounts() {
      const provider = await this.getProvider();
      if (!provider?.session?.namespaces) {
        return [];
      }
      const accountsList = provider?.session?.namespaces[ConstantsUtil.CHAIN.EVM]?.accounts;
      const accounts = accountsList?.map(account => account.split(':')[2]) ?? [];
      return accounts;
    },
    async getProvider({
      chainId
    } = {}) {
      if (!provider_) {
        provider_ = await appKit.getUniversalProvider();
        provider_?.events.setMaxListeners(Number.POSITIVE_INFINITY);
      }
      const activeNamespace = StorageUtil.getActiveNamespace();
      const currentChainId = appKit.getCaipNetwork()?.id;
      if (chainId && currentChainId !== chainId && activeNamespace) {
        const storedCaipNetworkId = StorageUtil.getStoredActiveCaipNetworkId();
        const appKitCaipNetworks = appKit?.getCaipNetworks(activeNamespace);
        const storedCaipNetwork = appKitCaipNetworks?.find(n => n.id === storedCaipNetworkId);
        if (storedCaipNetwork && storedCaipNetwork.chainNamespace === ConstantsUtil.CHAIN.EVM) {
          await this.switchChain?.({
            chainId: Number(storedCaipNetwork.id)
          });
        }
      }
      return provider_;
    },
    async getChainId() {
      const chainId = appKit.getCaipNetwork()?.id;
      if (chainId) {
        return chainId;
      }
      const provider = await this.getProvider();
      const chain = provider.session?.namespaces[ConstantsUtil.CHAIN.EVM]?.chains?.[0];
      const network = caipNetworks.find(c => c.id === chain);
      return network?.id;
    },
    async isAuthorized() {
      try {
        const [accounts, provider] = await Promise.all([this.getAccounts(), this.getProvider()]);
        if (!accounts.length) {
          return false;
        }
        const isChainsStale = await this.isChainsStale();
        if (isChainsStale && provider.session) {
          await provider.disconnect().catch(() => {});
          return false;
        }
        return true;
      } catch {
        return false;
      }
    },
    async switchChain({
      addEthereumChainParameter,
      chainId
    }) {
      const provider = await this.getProvider();
      if (!provider) {
        throw new ProviderNotFoundError();
      }
      const chainToSwitch = caipNetworks.find(x => x.id === chainId);
      if (!chainToSwitch) {
        throw new SwitchChainError(new ChainNotConfiguredError());
      }
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{
            chainId: numberToHex(chainId)
          }]
        });
        if (chainToSwitch?.caipNetworkId) {
          provider.setDefaultChain(chainToSwitch?.caipNetworkId);
        }
        config.emitter.emit('change', {
          chainId: Number(chainId)
        });
        const requestedChains = await this.getRequestedChainsIds();
        this.setRequestedChainsIds([...requestedChains, chainId]);
        return {
          ...chainToSwitch,
          id: chainToSwitch.id
        };
      } catch (err) {
        const error = err;
        if (/(?:user rejected)/iu.test(error.message)) {
          throw new UserRejectedRequestError(error);
        }
        try {
          let blockExplorerUrls;
          if (addEthereumChainParameter?.blockExplorerUrls) {
            blockExplorerUrls = addEthereumChainParameter.blockExplorerUrls;
          } else {
            blockExplorerUrls = chainToSwitch.blockExplorers?.default.url ? [chainToSwitch.blockExplorers?.default.url] : [];
          }
          const rpcUrls = chainToSwitch.rpcUrls?.['chainDefault']?.http || [];
          const addEthereumChain = {
            blockExplorerUrls,
            chainId: numberToHex(chainId),
            chainName: chainToSwitch.name,
            iconUrls: addEthereumChainParameter?.iconUrls,
            nativeCurrency: chainToSwitch.nativeCurrency,
            rpcUrls
          };
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [addEthereumChain]
          });
          const requestedChains = await this.getRequestedChainsIds();
          this.setRequestedChainsIds([...requestedChains, chainId]);
          return {
            ...chainToSwitch,
            id: chainToSwitch.id
          };
        } catch (e) {
          throw new UserRejectedRequestError(e);
        }
      }
    },
    onAccountsChanged(accounts) {
      if (accounts.length === 0) {
        this.onDisconnect();
      } else {
        config.emitter.emit('change', {
          accounts: accounts.map(x => getAddress(x))
        });
      }
    },
    onChainChanged(chain) {
      const chainId = Number(chain);
      config.emitter.emit('change', {
        chainId
      });
    },
    onConnect(_connectInfo) {
      this.setRequestedChainsIds(caipNetworks.map(x => Number(x.id)));
    },
    async onDisconnect(_error) {
      this.setRequestedChainsIds([]);
      config.emitter.emit('disconnect');
      const provider = await this.getProvider();
      if (accountsChanged) {
        provider.removeListener('accountsChanged', accountsChanged);
        accountsChanged = undefined;
      }
      if (chainChanged) {
        provider.removeListener('chainChanged', chainChanged);
        chainChanged = undefined;
      }
      if (disconnect) {
        provider.removeListener('disconnect', disconnect);
        disconnect = undefined;
      }
      if (sessionDelete) {
        provider.removeListener('session_delete', sessionDelete);
        sessionDelete = undefined;
      }
      if (!connect) {
        connect = this.onConnect.bind(this);
        provider.on('connect', connect);
      }
    },
    onDisplayUri(uri) {
      config.emitter.emit('message', {
        type: 'display_uri',
        data: uri
      });
    },
    onSessionDelete() {
      this.onDisconnect();
    },
    getNamespaceChainsIds() {
      if (!provider_?.session?.namespaces) {
        return [];
      }
      const accounts = provider_?.session?.namespaces[ConstantsUtil.CHAIN.EVM]?.accounts;
      const chainIds = accounts?.map(account => Number.parseInt(account.split(':')[1] ?? '')) ?? [];
      return chainIds;
    },
    async getRequestedChainsIds() {
      const chainIds = (await config.storage?.getItem(this.requestedChainsStorageKey)) ?? [];
      return [...new Set(chainIds)];
    },
    async isChainsStale() {
      if (!isNewChainsStale) {
        return false;
      }
      const connectorChains = config.chains.map(x => x.id);
      const namespaceChains = this.getNamespaceChainsIds();
      if (namespaceChains.length && !namespaceChains.some(id => connectorChains.includes(id))) {
        return false;
      }
      const requestedChains = await this.getRequestedChainsIds();
      return !connectorChains.every(id => requestedChains.includes(Number(id)));
    },
    async setRequestedChainsIds(chains) {
      await config.storage?.setItem(this.requestedChainsStorageKey, chains);
    },
    get requestedChainsStorageKey() {
      return `${this.id}.requestedChains`;
    }
  }));
}
