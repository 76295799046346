import { AdapterBlueprint } from '@reown/appkit/adapters';
import { CoreHelperUtil } from '@reown/appkit-core';
import { connect, disconnect as wagmiDisconnect, createConfig, getConnections, switchChain, injected, watchAccount, watchConnections, getBalance, getEnsName, getEnsAvatar, signMessage, estimateGas as wagmiEstimateGas, sendTransaction as wagmiSendTransaction, getEnsAddress as wagmiGetEnsAddress, writeContract as wagmiWriteContract, waitForTransactionReceipt, getAccount, prepareTransactionRequest, reconnect, watchPendingTransactions } from '@wagmi/core';
import '@wagmi/core/chains';
import { ConstantsUtil as CommonConstantsUtil, isReownName, NetworkUtil } from '@reown/appkit-common';
import { authConnector } from './connectors/AuthConnector.js';
import { AppKit, WcHelpersUtil } from '@reown/appkit';
import { walletConnect } from './connectors/UniversalConnector.js';
import { coinbaseWallet } from '@wagmi/connectors';
import { ConstantsUtil as CoreConstantsUtil } from '@reown/appkit-core';
import { CaipNetworksUtil, ConstantsUtil, PresetsUtil } from '@reown/appkit-utils';
import { formatUnits, parseUnits } from 'viem';
import { normalize } from 'viem/ens';
import { parseWalletCapabilities } from './utils/helpers.js';
export class WagmiAdapter extends AdapterBlueprint {
  constructor(configParams) {
    super({
      projectId: configParams.projectId,
      networks: CaipNetworksUtil.extendCaipNetworks(configParams.networks, {
        projectId: configParams.projectId,
        customNetworkImageUrls: {},
        customRpcChainIds: configParams.transports ? Object.keys(configParams.transports).map(Number) : []
      })
    });
    this.adapterType = 'wagmi';
    this.namespace = CommonConstantsUtil.CHAIN.EVM;
    this.createConfig({
      ...configParams,
      networks: CaipNetworksUtil.extendCaipNetworks(configParams.networks, {
        projectId: configParams.projectId,
        customNetworkImageUrls: {},
        customRpcChainIds: configParams.transports ? Object.keys(configParams.transports).map(Number) : []
      }),
      projectId: configParams.projectId
    });
    this.setupWatchers();
  }
  async getAccounts(params) {
    const connector = this.wagmiConfig.connectors.find(c => c.id === params.id);
    if (!connector) {
      throw new Error('WagmiAdapter:getAccounts - connector is undefined');
    }
    if (connector.id === ConstantsUtil.AUTH_CONNECTOR_ID) {
      const provider = connector['provider'];
      const {
        address,
        accounts
      } = await provider.connect();
      return Promise.resolve({
        accounts: (accounts || [{
          address,
          type: 'eoa'
        }]).map(account => CoreHelperUtil.createAccount('eip155', account.address, account.type))
      });
    }
    const {
      addresses,
      address
    } = getAccount(this.wagmiConfig);
    return Promise.resolve({
      accounts: (addresses || [address])?.map(val => CoreHelperUtil.createAccount('eip155', val || '', 'eoa'))
    });
  }
  createConfig(configParams) {
    this.caipNetworks = configParams.networks;
    this.wagmiChains = this.caipNetworks.filter(caipNetwork => caipNetwork.chainNamespace === CommonConstantsUtil.CHAIN.EVM);
    const transportsArr = this.wagmiChains.map(chain => [chain.id, CaipNetworksUtil.getViemTransport(chain)]);
    Object.entries(configParams.transports ?? {}).forEach(([chainId, transport]) => {
      const index = transportsArr.findIndex(([id]) => id === Number(chainId));
      if (index === -1) {
        transportsArr.push([Number(chainId), transport]);
      } else {
        transportsArr[index] = [Number(chainId), transport];
      }
    });
    const transports = Object.fromEntries(transportsArr);
    const connectors = [...(configParams.connectors ?? [])];
    this.wagmiConfig = createConfig({
      ...configParams,
      chains: this.wagmiChains,
      transports,
      connectors
    });
  }
  setupWatchers() {
    watchPendingTransactions(this.wagmiConfig, {
      pollingInterval: 15000,
      onError: () => {},
      onTransactions: () => {
        this.emit('pendingTransactions');
      }
    });
    watchAccount(this.wagmiConfig, {
      onChange: accountData => {
        if (accountData.address) {
          this.emit('accountChanged', {
            address: accountData.address,
            chainId: accountData.chainId
          });
        }
        if (accountData.chainId) {
          this.emit('switchNetwork', {
            address: accountData.address,
            chainId: accountData.chainId
          });
        }
      }
    });
    watchConnections(this.wagmiConfig, {
      onChange: connections => {
        if (connections.length === 0) {
          this.emit('disconnect');
        }
      }
    });
  }
  addWagmiConnectors(options, appKit) {
    const customConnectors = [];
    if (options.enableCoinbase !== false) {
      customConnectors.push(coinbaseWallet({
        version: '4',
        appName: options.metadata?.name ?? 'Unknown',
        appLogoUrl: options.metadata?.icons[0] ?? 'Unknown',
        preference: options.coinbasePreference ?? 'all'
      }));
    }
    if (options.enableWalletConnect !== false) {
      customConnectors.push(walletConnect(options, appKit, this.caipNetworks));
    }
    if (options.enableInjected !== false) {
      customConnectors.push(injected({
        shimDisconnect: true
      }));
    }
    const emailEnabled = options.features?.email === undefined ? CoreConstantsUtil.DEFAULT_FEATURES.email : options.features?.email;
    const socialsEnabled = options.features?.socials ? options.features?.socials?.length > 0 : CoreConstantsUtil.DEFAULT_FEATURES.socials;
    if (emailEnabled || socialsEnabled) {
      customConnectors.push(authConnector({
        chains: this.wagmiChains,
        options: {
          projectId: options.projectId
        },
        provider: this.availableConnectors.find(c => c.id === ConstantsUtil.AUTH_CONNECTOR_ID)?.provider
      }));
    }
    customConnectors.forEach(connector => {
      const cnctr = this.wagmiConfig._internal.connectors.setup(connector);
      this.wagmiConfig._internal.connectors.setState(prev => [...prev, cnctr]);
    });
  }
  async signMessage(params) {
    try {
      const signature = await signMessage(this.wagmiConfig, {
        message: params.message,
        account: params.address
      });
      return {
        signature
      };
    } catch (error) {
      throw new Error('WagmiAdapter:signMessage - Sign message failed');
    }
  }
  async sendTransaction(params) {
    const {
      chainId
    } = getAccount(this.wagmiConfig);
    const txParams = {
      account: params.address,
      to: params.to,
      value: params.value,
      gas: params.gas,
      gasPrice: params.gasPrice,
      data: params.data,
      chainId,
      type: 'legacy'
    };
    await prepareTransactionRequest(this.wagmiConfig, txParams);
    const tx = await wagmiSendTransaction(this.wagmiConfig, txParams);
    await waitForTransactionReceipt(this.wagmiConfig, {
      hash: tx,
      timeout: 25000
    });
    return {
      hash: tx
    };
  }
  async writeContract(params) {
    const {
      caipNetwork,
      ...data
    } = params;
    const chainId = Number(NetworkUtil.caipNetworkIdToNumber(caipNetwork.caipNetworkId));
    const tx = await wagmiWriteContract(this.wagmiConfig, {
      chain: this.wagmiChains?.[chainId],
      chainId,
      address: data.tokenAddress,
      account: data.fromAddress,
      abi: data.abi,
      functionName: data.method,
      args: [data.receiverAddress, data.tokenAmount]
    });
    return {
      hash: tx
    };
  }
  async getEnsAddress(params) {
    const {
      name,
      caipNetwork
    } = params;
    try {
      if (!this.wagmiConfig) {
        throw new Error('networkControllerClient:getApprovedCaipNetworksData - wagmiConfig is undefined');
      }
      let ensName = false;
      let wcName = false;
      if (isReownName(name)) {
        wcName = (await WcHelpersUtil.resolveReownName(name)) || false;
      }
      if (caipNetwork.id === 1) {
        ensName = await wagmiGetEnsAddress(this.wagmiConfig, {
          name: normalize(name),
          chainId: caipNetwork.id
        });
      }
      return {
        address: ensName || wcName || false
      };
    } catch {
      return {
        address: false
      };
    }
  }
  async estimateGas(params) {
    try {
      const result = await wagmiEstimateGas(this.wagmiConfig, {
        account: params.address,
        to: params.to,
        data: params.data,
        type: 'legacy'
      });
      return {
        gas: result
      };
    } catch (error) {
      throw new Error('WagmiAdapter:estimateGas - error estimating gas');
    }
  }
  parseUnits(params) {
    return parseUnits(params.value, params.decimals);
  }
  formatUnits(params) {
    return formatUnits(params.value, params.decimals);
  }
  syncConnectors(options, appKit) {
    this.addWagmiConnectors(options, appKit);
    const connectors = this.wagmiConfig.connectors.map(connector => ({
      ...connector,
      chain: this.namespace
    }));
    const uniqueIds = new Set();
    const filteredConnectors = connectors.filter(item => {
      const isDuplicate = uniqueIds.has(item.id);
      uniqueIds.add(item.id);
      return !isDuplicate;
    });
    filteredConnectors.forEach(connector => {
      const shouldSkip = ConstantsUtil.AUTH_CONNECTOR_ID === connector.id;
      const injectedConnector = connector.id === ConstantsUtil.INJECTED_CONNECTOR_ID;
      if (!shouldSkip && this.namespace) {
        this.addConnector({
          id: connector.id,
          explorerId: PresetsUtil.ConnectorExplorerIds[connector.id],
          imageUrl: options?.connectorImages?.[connector.id] ?? connector.icon,
          name: PresetsUtil.ConnectorNamesMap[connector.id] ?? connector.name,
          imageId: PresetsUtil.ConnectorImageIds[connector.id],
          type: PresetsUtil.ConnectorTypesMap[connector.type] ?? 'EXTERNAL',
          info: injectedConnector ? undefined : {
            rdns: connector.id
          },
          chain: this.namespace,
          chains: []
        });
      }
    });
  }
  async syncConnection(params) {
    const {
      id
    } = params;
    const connections = getConnections(this.wagmiConfig);
    const connection = connections.find(c => c.connector.id === id);
    const connector = this.wagmiConfig.connectors.find(c => c.id === id);
    const provider = await connector?.getProvider();
    return {
      chainId: Number(connection?.chainId),
      address: connection?.accounts[0],
      provider,
      type: connection?.connector.type,
      id: connection?.connector.id
    };
  }
  async connectWalletConnect(onUri, chainId) {
    const connector = this.wagmiConfig.connectors.find(c => c.type === 'walletConnect');
    const provider = await connector.getProvider();
    if (!this.caipNetworks || !provider) {
      throw new Error('UniversalAdapter:connectWalletConnect - caipNetworks or provider is undefined');
    }
    provider.on('display_uri', uri => {
      onUri(uri);
    });
    await connect(this.wagmiConfig, {
      connector,
      chainId: chainId ? Number(chainId) : undefined
    });
  }
  async connect(params) {
    const {
      id,
      provider,
      type,
      info,
      chainId
    } = params;
    const connector = this.wagmiConfig.connectors.find(c => c.id === id);
    if (!connector) {
      throw new Error('connectionControllerClient:connectExternal - connector is undefined');
    }
    if (provider && info && connector.id === ConstantsUtil.EIP6963_CONNECTOR_ID) {
      connector.setEip6963Wallet?.({
        provider,
        info
      });
    }
    const res = await connect(this.wagmiConfig, {
      connector,
      chainId: chainId ? Number(chainId) : undefined
    });
    return {
      address: res.accounts[0],
      chainId: res.chainId,
      provider: provider,
      type: type,
      id
    };
  }
  async reconnect(params) {
    const {
      id
    } = params;
    const connector = this.wagmiConfig.connectors.find(c => c.id === id);
    if (!connector) {
      throw new Error('connectionControllerClient:connectExternal - connector is undefined');
    }
    await reconnect(this.wagmiConfig, {
      connectors: [connector]
    });
  }
  async getBalance(params) {
    const caipNetwork = this.caipNetworks?.find(network => network.id === params.chainId);
    if (caipNetwork && this.wagmiConfig) {
      const chainId = Number(params.chainId);
      const balance = await getBalance(this.wagmiConfig, {
        address: params.address,
        chainId,
        token: params.tokens?.[caipNetwork.caipNetworkId]?.address
      });
      return {
        balance: balance.formatted,
        symbol: balance.symbol
      };
    }
    return {
      balance: '',
      symbol: ''
    };
  }
  async getProfile(params) {
    const chainId = params.chainId;
    const profileName = await getEnsName(this.wagmiConfig, {
      address: params.address,
      chainId
    });
    if (profileName) {
      const profileImage = await getEnsAvatar(this.wagmiConfig, {
        name: profileName,
        chainId
      });
      return {
        profileName,
        profileImage: profileImage ?? undefined
      };
    }
    return {
      profileName: undefined,
      profileImage: undefined
    };
  }
  getWalletConnectProvider() {
    return this.wagmiConfig.connectors.find(c => c.type === 'walletConnect')?.['provider'];
  }
  async disconnect() {
    const connections = getConnections(this.wagmiConfig);
    await Promise.all(connections.map(async connection => {
      const connector = connection?.connector;
      if (connector) {
        await wagmiDisconnect(this.wagmiConfig, {
          connector
        });
      }
    }));
  }
  async switchNetwork(params) {
    await switchChain(this.wagmiConfig, {
      chainId: params.caipNetwork.id
    });
  }
  async getCapabilities(params) {
    if (!this.wagmiConfig) {
      throw new Error('connectionControllerClient:getCapabilities - wagmiConfig is undefined');
    }
    const connections = getConnections(this.wagmiConfig);
    const connection = connections[0];
    if (!connection?.connector) {
      throw new Error('connectionControllerClient:getCapabilities - connector is undefined');
    }
    const provider = await connection.connector.getProvider();
    if (!provider) {
      throw new Error('connectionControllerClient:getCapabilities - provider is undefined');
    }
    const walletCapabilitiesString = provider.session?.sessionProperties?.['capabilities'];
    if (walletCapabilitiesString) {
      const walletCapabilities = parseWalletCapabilities(walletCapabilitiesString);
      const accountCapabilities = walletCapabilities[params];
      if (accountCapabilities) {
        return accountCapabilities;
      }
    }
    return await provider.request({
      method: 'wallet_getCapabilities',
      params: [params]
    });
  }
  async grantPermissions(params) {
    if (!this.wagmiConfig) {
      throw new Error('connectionControllerClient:grantPermissions - wagmiConfig is undefined');
    }
    const connections = getConnections(this.wagmiConfig);
    const connection = connections[0];
    if (!connection?.connector) {
      throw new Error('connectionControllerClient:grantPermissions - connector is undefined');
    }
    const provider = await connection.connector.getProvider();
    if (!provider) {
      throw new Error('connectionControllerClient:grantPermissions - provider is undefined');
    }
    return provider.request({
      method: 'wallet_grantPermissions',
      params
    });
  }
  async revokePermissions(params) {
    if (!this.wagmiConfig) {
      throw new Error('connectionControllerClient:revokePermissions - wagmiConfig is undefined');
    }
    const connections = getConnections(this.wagmiConfig);
    const connection = connections[0];
    if (!connection?.connector) {
      throw new Error('connectionControllerClient:revokePermissions - connector is undefined');
    }
    const provider = await connection.connector.getProvider();
    if (!provider) {
      throw new Error('connectionControllerClient:revokePermissions - provider is undefined');
    }
    return provider.request({
      method: 'wallet_revokePermissions',
      params
    });
  }
}
