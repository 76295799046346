var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { MathUtil, customElement } from '@reown/appkit-ui';
import { LitElement, html } from 'lit';
import styles from './styles.js';
import { ConnectionController, ConnectorController, CoreHelperUtil, OptionsController, RouterController } from '@reown/appkit-core';
import { state } from 'lit/decorators/state.js';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { ConstantsUtil } from '@reown/appkit-core';
const defaultConnectMethodsOrder = ConstantsUtil.DEFAULT_FEATURES.connectMethodsOrder;
let W3mConnectView = class W3mConnectView extends LitElement {
  constructor() {
    super();
    this.unsubscribe = [];
    this.connectors = ConnectorController.state.connectors;
    this.authConnector = this.connectors.find(c => c.type === 'AUTH');
    this.features = OptionsController.state.features;
    this.enableWallets = OptionsController.state.enableWallets;
    this.walletGuide = 'get-started';
    this.checked = false;
    this.unsubscribe.push(ConnectorController.subscribeKey('connectors', val => {
      this.connectors = val;
      this.authConnector = this.connectors.find(c => c.type === 'AUTH');
    }), OptionsController.subscribeKey('features', val => this.features = val), OptionsController.subscribeKey('enableWallets', val => this.enableWallets = val));
  }
  disconnectedCallback() {
    this.unsubscribe.forEach(unsubscribe => unsubscribe());
    const connectEl = this.shadowRoot?.querySelector('.connect');
    connectEl?.removeEventListener('scroll', this.handleConnectListScroll.bind(this));
  }
  firstUpdated() {
    const connectEl = this.shadowRoot?.querySelector('.connect');
    requestAnimationFrame(this.handleConnectListScroll.bind(this));
    connectEl?.addEventListener('scroll', this.handleConnectListScroll.bind(this));
  }
  render() {
    const {
      termsConditionsUrl,
      privacyPolicyUrl
    } = OptionsController.state;
    const legalCheckbox = OptionsController.state.features?.legalCheckbox;
    const legalUrl = termsConditionsUrl || privacyPolicyUrl;
    const showLegalCheckbox = Boolean(legalUrl) && Boolean(legalCheckbox) && this.walletGuide === 'get-started';
    const disabled = showLegalCheckbox && !this.checked;
    const classes = {
      connect: true,
      disabled
    };
    const enableWalletGuide = OptionsController.state.enableWalletGuide;
    const socials = this.features?.socials;
    const enableWallets = this.enableWallets;
    const socialsExist = socials && socials.length;
    const socialOrEmailLoginEnabled = socialsExist || this.authConnector;
    const tabIndex = disabled ? -1 : undefined;
    return html`
      <wui-flex flexDirection="column">
        ${this.legalCheckboxTemplate()}
        <wui-flex
          data-testid="w3m-connect-scroll-view"
          flexDirection="column"
          class=${classMap(classes)}
        >
          <wui-flex
            class="connect-methods"
            flexDirection="column"
            gap="s"
            .padding=${socialOrEmailLoginEnabled && enableWallets && enableWalletGuide && this.walletGuide === 'get-started' ? ['3xs', 's', '0', 's'] : ['3xs', 's', 's', 's']}
          >
            ${this.renderConnectMethod(tabIndex)}
          </wui-flex>
        </wui-flex>
        ${this.guideTemplate(disabled)}
        <w3m-legal-footer></w3m-legal-footer>
      </wui-flex>
    `;
  }
  renderConnectMethod(tabIndex) {
    const connectMethodsOrder = this.features?.connectMethodsOrder || defaultConnectMethodsOrder;
    if (!connectMethodsOrder) {
      return null;
    }
    return html`${connectMethodsOrder.map((method, index) => {
      switch (method) {
        case 'email':
          return html`${this.emailTemplate(tabIndex)} ${this.separatorTemplate(index, 'email')}`;
        case 'social':
          return html`${this.socialListTemplate(tabIndex)}
          ${this.separatorTemplate(index, 'social')}`;
        case 'wallet':
          return html`${this.walletListTemplate(tabIndex)}
          ${this.separatorTemplate(index, 'wallet')}`;
        default:
          return null;
      }
    })}`;
  }
  checkMethodEnabled(name) {
    switch (name) {
      case 'wallet':
        return this.enableWallets;
      case 'social':
        return this.features?.socials && this.features?.socials.length > 0;
      case 'email':
        return this.features?.email;
      default:
        return null;
    }
  }
  checkIsThereNextMethod(currentIndex) {
    const connectMethodsOrder = this.features?.connectMethodsOrder || defaultConnectMethodsOrder;
    const nextMethod = connectMethodsOrder[currentIndex + 1];
    if (!nextMethod) {
      return undefined;
    }
    const isNextMethodEnabled = this.checkMethodEnabled(nextMethod);
    if (isNextMethodEnabled) {
      return nextMethod;
    }
    return this.checkIsThereNextMethod(currentIndex + 1);
  }
  separatorTemplate(index, type) {
    const nextEnabledMethod = this.checkIsThereNextMethod(index);
    const isExplore = this.walletGuide === 'explore';
    switch (type) {
      case 'wallet':
        {
          const isWalletEnable = this.enableWallets;
          return isWalletEnable && nextEnabledMethod && !isExplore ? html`<wui-separator data-testid="wui-separator" text="or"></wui-separator>` : null;
        }
      case 'email':
        {
          const isEmailEnabled = this.features?.email;
          const isNextMethodSocial = nextEnabledMethod === 'social';
          if (isExplore) {
            return null;
          }
          return isEmailEnabled && !isNextMethodSocial && nextEnabledMethod ? html`<wui-separator
              data-testid="w3m-email-login-or-separator"
              text="or"
            ></wui-separator>` : null;
        }
      case 'social':
        {
          const isSocialEnabled = this.features?.socials && this.features?.socials.length > 0;
          const isNextMethodEmail = nextEnabledMethod === 'email';
          if (isExplore) {
            return null;
          }
          return isSocialEnabled && !isNextMethodEmail && nextEnabledMethod ? html`<wui-separator data-testid="wui-separator" text="or"></wui-separator>` : null;
        }
      default:
        return null;
    }
  }
  emailTemplate(tabIndex) {
    const emailEnabled = this.features?.email;
    const isCreateWalletPage = this.walletGuide === 'explore';
    if (!isCreateWalletPage && !emailEnabled) {
      return null;
    }
    return html`<w3m-email-login-widget
      walletGuide=${this.walletGuide}
      tabIdx=${ifDefined(tabIndex)}
    ></w3m-email-login-widget>`;
  }
  socialListTemplate(tabIndex) {
    const isSocialsEnabled = this.features?.socials && this.features?.socials.length > 0;
    const isCreateWalletPage = this.walletGuide === 'explore';
    if (!isCreateWalletPage && !isSocialsEnabled) {
      return null;
    }
    return html`<w3m-social-login-widget
      walletGuide=${this.walletGuide}
      tabIdx=${ifDefined(tabIndex)}
    ></w3m-social-login-widget>`;
  }
  walletListTemplate(tabIndex) {
    const enableWallets = this.enableWallets;
    const collapseWalletsOldProp = this.features?.emailShowWallets === false;
    const collapseWallets = this.features?.collapseWallets;
    const shouldCollapseWallets = collapseWalletsOldProp || collapseWallets;
    if (!enableWallets) {
      return null;
    }
    if (CoreHelperUtil.isTelegram() && CoreHelperUtil.isIos()) {
      ConnectionController.connectWalletConnect().catch(_e => ({}));
    }
    if (this.walletGuide === 'explore') {
      return null;
    }
    const hasEmail = this.features?.email;
    const hasSocials = this.features?.socials && this.features.socials.length > 0;
    const hasOtherMethods = hasEmail || hasSocials;
    if (hasOtherMethods && shouldCollapseWallets) {
      return html`<wui-list-button
        data-testid="w3m-collapse-wallets-button"
        tabIdx=${ifDefined(tabIndex)}
        @click=${this.onContinueWalletClick.bind(this)}
        text="Continue with a wallet"
      ></wui-list-button>`;
    }
    return html`<w3m-wallet-login-list tabIdx=${ifDefined(tabIndex)}></w3m-wallet-login-list>`;
  }
  guideTemplate(disabled = false) {
    const enableWalletGuide = OptionsController.state.enableWalletGuide;
    if (!enableWalletGuide) {
      return null;
    }
    const socials = this.features?.socials;
    const socialsExist = socials && socials.length;
    const classes = {
      guide: true,
      disabled
    };
    const tabIndex = disabled ? -1 : undefined;
    if (!this.authConnector && !socialsExist) {
      return null;
    }
    return html`
      ${this.walletGuide === 'explore' ? html`<wui-separator data-testid="wui-separator" id="explore" text="or"></wui-separator>` : null}
      <wui-flex flexDirection="column" .padding=${['s', '0', 'xl', '0']} class=${classMap(classes)}>
        <w3m-wallet-guide
          tabIdx=${ifDefined(tabIndex)}
          walletGuide=${this.walletGuide}
        ></w3m-wallet-guide>
      </wui-flex>
    `;
  }
  legalCheckboxTemplate() {
    if (this.walletGuide === 'explore') {
      return null;
    }
    return html`<w3m-legal-checkbox
      @checkboxChange=${this.onCheckboxChange.bind(this)}
      data-testid="w3m-legal-checkbox"
    ></w3m-legal-checkbox>`;
  }
  handleConnectListScroll() {
    const connectEl = this.shadowRoot?.querySelector('.connect');
    if (!connectEl || connectEl.scrollHeight <= 470) {
      return;
    }
    connectEl.style.setProperty('--connect-scroll--top-opacity', MathUtil.interpolate([0, 50], [0, 1], connectEl.scrollTop).toString());
    connectEl.style.setProperty('--connect-scroll--bottom-opacity', MathUtil.interpolate([0, 50], [0, 1], connectEl.scrollHeight - connectEl.scrollTop - connectEl.offsetHeight).toString());
  }
  onContinueWalletClick() {
    RouterController.push('ConnectWallets');
  }
  onCheckboxChange(event) {
    this.checked = Boolean(event.detail);
  }
};
W3mConnectView.styles = styles;
__decorate([state()], W3mConnectView.prototype, "connectors", void 0);
__decorate([state()], W3mConnectView.prototype, "authConnector", void 0);
__decorate([state()], W3mConnectView.prototype, "features", void 0);
__decorate([state()], W3mConnectView.prototype, "enableWallets", void 0);
__decorate([property()], W3mConnectView.prototype, "walletGuide", void 0);
__decorate([state()], W3mConnectView.prototype, "checked", void 0);
W3mConnectView = __decorate([customElement('w3m-connect-view')], W3mConnectView);
export { W3mConnectView };
