import { ConstantsUtil } from '@reown/appkit-common';
import { PresetsUtil } from './PresetsUtil.js';
import { fallback, http } from 'viem';
const RPC_URL_HOST = 'rpc.walletconnect.org';
export function getBlockchainApiRpcUrl(caipNetworkId, projectId) {
  const url = new URL('https://rpc.walletconnect.org/v1/');
  url.searchParams.set('chainId', caipNetworkId);
  url.searchParams.set('projectId', projectId);
  return url.toString();
}
const WC_HTTP_RPC_SUPPORTED_CHAINS = ['near:mainnet', 'solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp', 'eip155:1101', 'eip155:56', 'eip155:42161', 'eip155:7777777', 'eip155:59144', 'eip155:324', 'solana:EtWTRABZaYq6iMfeYKouRu166VU2xqa1', 'eip155:5000', 'solana:4sgjmw1sunhzsxgspuhpqldx6wiyjntz', 'eip155:80084', 'eip155:5003', 'eip155:100', 'eip155:8453', 'eip155:42220', 'eip155:1313161555', 'eip155:17000', 'eip155:1', 'eip155:300', 'eip155:1313161554', 'eip155:1329', 'eip155:84532', 'eip155:421614', 'eip155:11155111', 'eip155:8217', 'eip155:43114', 'solana:4uhcVJyU9pJkvQyS88uRDiswHXSCkY3z', 'eip155:999999999', 'eip155:11155420', 'eip155:80002', 'eip155:97', 'eip155:43113', 'eip155:137', 'eip155:10', 'eip155:1301', 'bip122:000000000019d6689c085ae165831e93', 'bip122:000000000933ea01ad0ee984209779ba'];
export const CaipNetworksUtil = {
  extendRpcUrlWithProjectId(rpcUrl, projectId) {
    let isReownUrl = false;
    try {
      const url = new URL(rpcUrl);
      isReownUrl = url.host === RPC_URL_HOST;
    } catch (e) {
      isReownUrl = false;
    }
    if (isReownUrl) {
      const url = new URL(rpcUrl);
      if (!url.searchParams.has('projectId')) {
        url.searchParams.set('projectId', projectId);
      }
      return url.toString();
    }
    return rpcUrl;
  },
  isCaipNetwork(network) {
    return 'chainNamespace' in network && 'caipNetworkId' in network;
  },
  getChainNamespace(network) {
    if (this.isCaipNetwork(network)) {
      return network.chainNamespace;
    }
    return ConstantsUtil.CHAIN.EVM;
  },
  getCaipNetworkId(network) {
    if (this.isCaipNetwork(network)) {
      return network.caipNetworkId;
    }
    return `${ConstantsUtil.CHAIN.EVM}:${network.id}`;
  },
  getDefaultRpcUrl(caipNetwork, caipNetworkId, projectId) {
    const defaultRpcUrl = caipNetwork.rpcUrls?.default?.http?.[0];
    if (WC_HTTP_RPC_SUPPORTED_CHAINS.includes(caipNetworkId)) {
      return getBlockchainApiRpcUrl(caipNetworkId, projectId);
    }
    return defaultRpcUrl || '';
  },
  extendCaipNetwork(caipNetwork, {
    customNetworkImageUrls,
    projectId,
    customRpc
  }) {
    const caipNetworkId = this.getCaipNetworkId(caipNetwork);
    const chainNamespace = this.getChainNamespace(caipNetwork);
    let rpcUrl = '';
    if (customRpc) {
      rpcUrl = caipNetwork.rpcUrls.default.http?.[0] || '';
    } else {
      rpcUrl = this.getDefaultRpcUrl(caipNetwork, caipNetworkId, projectId);
    }
    return {
      ...caipNetwork,
      chainNamespace,
      caipNetworkId,
      assets: {
        imageId: PresetsUtil.NetworkImageIds[caipNetwork.id],
        imageUrl: customNetworkImageUrls?.[caipNetwork.id]
      },
      rpcUrls: {
        ...caipNetwork.rpcUrls,
        default: {
          http: [rpcUrl]
        },
        chainDefault: {
          http: [caipNetwork.rpcUrls.default.http[0] || '']
        }
      }
    };
  },
  extendCaipNetworks(caipNetworks, {
    customNetworkImageUrls,
    projectId,
    customRpcChainIds
  }) {
    return caipNetworks.map(caipNetwork => CaipNetworksUtil.extendCaipNetwork(caipNetwork, {
      customNetworkImageUrls,
      projectId,
      customRpc: customRpcChainIds?.includes(caipNetwork.id)
    }));
  },
  getViemTransport(caipNetwork) {
    const chainDefaultUrl = caipNetwork.rpcUrls.default.http?.[0];
    if (!WC_HTTP_RPC_SUPPORTED_CHAINS.includes(caipNetwork.caipNetworkId)) {
      return http(chainDefaultUrl);
    }
    return fallback([http(chainDefaultUrl, {
      fetchOptions: {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    }), http(chainDefaultUrl)]);
  }
};
